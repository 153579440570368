import React from 'react';
import PropTypes from 'prop-types';

function Page({ children }) {
  return (
    <div className="page">
      <div className="page__content">{children}</div>
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
